import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash"
import firebase from './api/firebase'
import moment from "moment";

import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Splash from "./views/Splash"
import {apiRoutes} from "./constants/config";
import {setCurrentHouse, setCurrentScreen, setUserId, setSessionId} from "./redux/house";
import {setHouseConfig} from "./redux/house/api";
import {setCurrentOrg} from "./redux/organization";
import {setStartDate, setEndDate, setSelectedTimeFilter} from "./redux/date"
import {connect} from "react-redux";
import mixpanel from 'mixpanel-browser';

import {SCREENS} from './constants';

import {history} from "./redux/root-reducer"
import {ConnectedRouter} from 'connected-react-router'
import {BrowserRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import {analytics} from "./api/firebase";

import 'bootstrap/dist/css/bootstrap.min.css';
import {identifyMixpanel, initMixpanel} from "./utils/analytics";
import BlockedPopup from "./components/BlockedPopup";
import {setLimitReached} from "./redux/aiUnitsLimit";

class App extends Component {

    constructor() {
        super();
        this.state = {
            authLoaded: false,
            user: null,
            authSuccess: false,
            houseKey: null,
            houses: [],
            organization: null,
            currentPath: "",
            ipError: false,
        }
    }

    componentDidMount() {
        initMixpanel()

        firebase.auth().onAuthStateChanged(async (user) => {
           // console.log("onAuthStateChanged", user)
            if (user) {
                const {
                    displayName,
                    email,
                    emailVerified,
                    photoURL,
                    isAnonymous,
                    uid,
                    providerData
                } = user;

                const idToken = await user.getIdToken();
               // console.log("idToken changed", idToken)
                localStorage.setItem("userToken", idToken)


                analytics.setUserId(user.uid);
                let headers = new Headers()
                headers.append("Content-Type", "application/json");
                headers.append("Authorization", `Bearer ${idToken}`)
                let init = {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({})
                };

                try {
                    let response = await fetch(apiRoutes.loginValidation, init)
                    let jsonRes = await response.json();

                    if (!jsonRes.success) {
                        console.log("error validating user", jsonRes)
                        if(jsonRes.message === "units limit reached"){
                            this.props.setAiUnitsLimitReached(true)
                        }

                        if (jsonRes.code === "invalid_ip") {
                            this.setState({
                                authLoaded: true,
                                user: null,
                                authSuccess: false,
                                ipError: true
                            })
                        } else {

                            this.setState({
                                authLoaded: true,
                                user: null,
                                authSuccess: false,
                                ipError: false
                            })
                        }

                        return;

                    }


                    this.onHouseValidation(jsonRes);


                    this.initAuth(idToken, user, displayName, jsonRes.selectedHouse, jsonRes.selectedOrg);
                } catch (e) {
                    console.error("error validating user", e)

                }

            } else {
                // User is signed out.
                this.setState({
                    authLoaded: true,
                    user: null,
                    authSuccess: false,
                    ipError: false
                })
                analytics.setUserId(null);
            }
        });

        firebase.auth().onIdTokenChanged(async (user) => {
            if (user) {
                // User is signed in or token was refreshed.
                const idToken = await user.getIdToken();
               // console.log("idToken changed", idToken)
                localStorage.setItem("userToken", idToken)
                analytics.setUserId(user.uid);

            } else {
                // User is signed out.
                this.setState({
                    authLoaded: true,
                    user: null,
                    authSuccess: false,
                    ipError: false
                })
                analytics.setUserId(null);
            }
        })
    }

    initAuth = (idToken, user, displayName, house, organization) => {
        if (localStorage.getItem("userToken") !== idToken) {
            setTimeout(() => {
                this.initAuth(idToken, user, displayName, house, organization)
            }, 100)
        } else {
            let state = {
                authLoaded: true,
                user,
            };

            if (displayName) {
                state["authSuccess"] = true;
            }

            this.setState(state)

            identifyMixpanel(user.uid, user.email, house, organization)
        }
    }

    onHouseValidation = ({
                             success,
                             selectedHouse,
                             selectedOrg,
                             houses,
                             orgs
                         }) => {

        const {setCurrentHouse, setHouseConfig, setCurrentOrg} = this.props
        let housesToSwitch = houses || [];

        if (orgs && orgs.length > 0) {
            let map = orgs.map(o => {
                return {
                    ...o,
                    type: "org"
                }
            });
            housesToSwitch.push(...map)
        }

        setCurrentHouse(selectedHouse);
        console.log("set house", selectedHouse)
        selectedHouse && setHouseConfig({houseId: selectedHouse.id})
        setCurrentOrg(selectedOrg);

        this.setState({
            house: selectedHouse,
            houses,
            organization: selectedOrg,
            currentOrganization: selectedOrg,
            organizations: orgs,
            housesToSwitch,
            authSuccess: true
        })

    }

    handleMatchProps = (match) => {

        const setDates = (startDate, endDate, selectedTimeFilter) => {
            const {setStartDate, setEndDate, setSelectedTimeFilter} = this.props
            setStartDate(moment(startDate))
            setEndDate(moment(endDate))
            setSelectedTimeFilter(selectedTimeFilter)
        }

        const getCurrentScreen = () => {
            const {path} = match
            if (path.includes("user-sessions")) {
                return SCREENS.SESSIONS
            } else if (path.includes("session")) {
                return SCREENS.CONTENT
            } else if (path.includes("videos")) {
                return SCREENS.VIDEO
            } else if (path.includes("billing")) {
                return SCREENS.BILLING
            } else if(path.includes("groups")){
                return SCREENS.GROUP_MGMT
            }
            else {
                return SCREENS.USERS
            }
        }

        if (Object.keys(match.params).length) {
            let {startDate, endDate, selectedTimeFilter, userId, sessionId} = match.params
            const {setCurrentScreen, setUserId, setSessionId} = this.props

            startDate && setDates(new Date(parseInt(startDate)), new Date(parseInt(endDate)), selectedTimeFilter)

            const currentScreen = getCurrentScreen()
            setCurrentScreen(currentScreen)
            userId && setUserId(userId)
            sessionId && setSessionId(sessionId)
        }

        this.setState({currentPath: match.path})
    }

    renderRoute = ({match, houseType, name}) => {
        const {currentPath} = this.state
        let handleMatch = true

        if (match.path === currentPath) {
            handleMatch = false
        }

        handleMatch && this.handleMatchProps(match)

        const {
            authLoaded,
            user,
            authSuccess,
            organization,
            currentOrganization,
            housesToSwitch
        } = this.state;

        const {currentHouse} = this.props

        return (<Dashboard
                user={user}
                houseName={name}
                houses={housesToSwitch}
                houseChangeEnabled={housesToSwitch.length > 0}
                needToSelectHouse={!currentOrganization && !currentHouse}
                ref={el => (this.componentRef = el)}
                houseType={houseType}
                currentOrganization={currentOrganization}
            />
        )
    }

    render() {
        const {
            authLoaded,
            authSuccess,
            organization,
            ipError
        } = this.state;

        if (!authLoaded) {
            return <Splash/>
        }

        if (!authSuccess) {
            console.log("ipError", ipError)
            return <Login aiUnitsLimitReached={this.props.aiUnitsLimitReached} onHouseValidation={this.onHouseValidation} ipError={ipError}/>
        }

        let name = _.get(this.state.house, "name", "")
        let houseType

        if (organization) {
            name = organization.name;
            houseType = 'org'
        }

        return (
            <Fragment>
                <ConnectedRouter history={history}>
                    <>
                        <Switch>
                            <Route exact path="/users/:selectedTimeFilter/:startDate/:endDate"
                                   render={({match, location}) => this.renderRoute({match, houseType, name})}/>

                            <Route exact path="/user-sessions/:userId/:selectedTimeFilter/:startDate/:endDate"
                                   render={({match, location}) => this.renderRoute({match, houseType, name})}/>

                            <Route exact path="/videos"
                                   render={({match, location}) => this.renderRoute({match, houseType, name})}/>

                            <Route exact path="/billing"
                                   render={({match, location}) => this.renderRoute({match, houseType, name})}/>

                            <Route exact path="/groups"
                                   render={({match, location}) => this.renderRoute({match, houseType, name})}/>



                            <Route exact path="/session/:userId/:sessionId"
                                   render={({match, location}) => this.renderRoute({match, houseType, name})}/>

                            <Route exact path="/test" render={() => <h1>test</h1>}/>

                            <Route exact path="/" render={({match, location}) => <>
                                {this.renderRoute({match, houseType, name})}
                            </>}/>
                        </Switch>
                    </>
                </ConnectedRouter>

                <BlockedPopup
                    open={this.props.aiUnitsLimitReached}
                    closeHandler={()=>{}}
                />

            </Fragment>
        );
    }
}

const mapStateToProps = ({translations, date, table, top, house, aiUnitsLimit}) => ({
    currentHouse: house.currentHouse,
    currentLanguage: translations.currentLanguage,
    translationsLoaded: translations.translationsLoaded,
    selectedTimeFilter: date.selectedTimeFilter,
    startDate: date.startDate,
    endDate: date.endDate,
    loadingTable: table.loadingTable,
    tableData: table.tableData,
    loadingTopData: top.loadingTopData,
    topData: top.topData,
    aiUnitsLimitReached: aiUnitsLimit.limitReached
});

const mapDispatchToProps = {
    setCurrentHouse,
    setStartDate,
    setEndDate,
    setSelectedTimeFilter,
    setCurrentScreen,
    setUserId,
    setSessionId,
    setHouseConfig,
    setCurrentOrg,
    setAiUnitsLimitReached: setLimitReached
};

export default connect(mapStateToProps, mapDispatchToProps)(App);


App.propTypes = {};
