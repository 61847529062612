//const apiUrl = "http://localhost:8080"//process.env.REACT_APP_API_URL
import { buildQueryParams } from "../utils";

const apiUrl = process.env.REACT_APP_API_URL


const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const routes = {
    houseReport: (startDate, endDate, compareTrendKey, tags, timeZone, tagFilteringMode) => apiUrl + `/api/dashboard/house?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tags=${JSON.stringify(tags)}&tz=${timeZone}&tagFilteringMode=${tagFilteringMode}`,
    exportSessions: (startDate, endDate, tags, timeZone, tagFilteringMode, residentId) => apiUrl + `/api/dashboard/export-sessions?startDate=${startDate}&endDate=${endDate}&tags=${JSON.stringify(tags)}&tz=${timeZone}${residentId ? `&residentId=${residentId}` : ""}&tagFilteringMode=${tagFilteringMode}`,
    exportVideoData: (startDate, endDate, timeZone, videoID) => apiUrl + `/api/dashboard/export-video?startDate=${startDate}&endDate=${endDate}&tz=${timeZone}&videoId=${videoID}`,
    exportTableVideos: (startDate, endDate, timeZone, tags, tagFilteringMode) => apiUrl + `/api/dashboard/export-table-videos?startDate=${startDate}&endDate=${endDate}&tz=${timeZone}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}`,
    houseResidentsReport: (startDate, endDate, compareTrendKey, tags, tagFilteringMode) => apiUrl + `/api/dashboard/residents?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tags=${JSON.stringify(tags)}&tz=${timeZone}&tagFilteringMode=${tagFilteringMode}`,
    residentReport: (startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode) => apiUrl + `/api/dashboard/resident/${residentId}?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tz=${timeZone}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}`,
    residentId: (houseId) => apiUrl + `/api/dashboard/get-resident/${houseId}`, //todo: remove not needed anymore
    residentSessionsReport: (startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode) => apiUrl + `/api/dashboard/resident-sessions/${residentId}?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tz=${timeZone}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}`,
    residentSessionReport: (startDate, endDate, compareTrendKey, residentId, sessionId) => apiUrl + `/api/dashboard/resident-session/${residentId}/${sessionId}?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tz=${timeZone}`,
    sessionTracksReport: (startDate, endDate, compareTrendKey, residentId, sessionId) => apiUrl + `/api/dashboard/session-tracks/${residentId}/${sessionId}?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tz=${timeZone}`,
    tableOverTimeData: (sessionId, contentId) => apiUrl + `/api/dashboard/table-row/overtime/${sessionId}/${contentId}`,
    tableVideoOverTimeData: (videoId) => apiUrl + `/api/dashboard/video/overtime/${videoId}`,
    videoProcessedData: (videoId) => apiUrl + `/api/dashboard/get-video-result/${videoId}`,
    // tableVideoData: (videoId) => apiUrl + `/api/dashboard/table-row/video/${videoId}`, //todo add to server
    videosReport: (startDate, endDate, tags, tagFilteringMode) => apiUrl + `/api/dashboard/videos-report?startDate=${startDate}&endDate=${endDate}&tz=${timeZone}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}`,
    videosTableReport: (startDate, endDate, tags, tagFilteringMode) => apiUrl + `/api/dashboard/videos-data?startDate=${startDate}&endDate=${endDate}&tz=${timeZone}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}`,
    saveAnalyzedVideo: `${apiUrl}/api/dashboard/save-analyzed-video`,
    residentComment: apiUrl + "/api/dashboard/resident-comment",
    sessionComment: apiUrl + "/api/dashboard/session-comment",
    loginValidation: apiUrl + "/api/dashboard/loginValidation",
    dashboardTags: (context) => apiUrl + `/api/dashboard/tags/${context}`,
    addResidentTag: apiUrl + "/api/dashboard/add-resident-tag",
    addSessionTag: apiUrl + "/api/dashboard/add-session-tag",
    addVideoTag: apiUrl + "/api/dashboard/add-video-tag",
    uploadFile: apiUrl + "/api/dashboard/upload-video",
    deleteVideo: apiUrl + "/api/dashboard/delete-video",
    updateVideo: apiUrl + "/api/dashboard/update-video",
    deleteResidentTag: apiUrl + "/api/dashboard/delete-resident-tag",
    deleteSessionTag: apiUrl + "/api/dashboard/delete-session-tag",
    deleteVideoTag: apiUrl + "/api/dashboard/delete-video-tag",
    houseLogin: apiUrl + "/api/house/admin-login",
    getAnnotations: (annotationType, relationId) => apiUrl + `/api/dashboard/annotations/${annotationType}/${relationId}`,
    addAnnotation: apiUrl + "/api/dashboard/add-annotation",
    editAnnotation: apiUrl + "/api/dashboard/update-annotation",
    deleteAnnotation: apiUrl + "/api/dashboard/delete-annotation",
    getHouseConfig: (houseId) => `${apiUrl}/api/house/${houseId}/config`,


    houseLogout: apiUrl + "/api/house/logout",
    adminHouses: apiUrl + "/api/dashboard/admin-houses",
    orgLogin: apiUrl + "/api/organization/admin-login",
}
